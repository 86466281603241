import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeclinedLoads } from '../../../../../../../api/activityLog/interfaces';
import { activityLogApi } from '../../../../../../../api/activityLog/activityLogApi';

export const fetchDeclinedLoadsList = createAsyncThunk(
    'fetchDeclinedLoadsList',
    async (_, { rejectWithValue }) => {
        try {
            const response = await activityLogApi.fetchDeclinedLoads();
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const SELECTED_INITIAL_STATE: IDeclinedLoads = {
    driverLoadId: 0,
    driverName: '',
    declinedDateTime: '',
    customerName: '',
    shipmentId: 0,
    location: [],
};

export type IDeclinedLoadsInitialState = {
    declinedLoadsLoading: boolean;
    declinedLoadsList: IDeclinedLoads[];
};

const initialDeclinedLoadsState: IDeclinedLoadsInitialState = {
    declinedLoadsLoading: false,
    declinedLoadsList: [],
};

export const declinedLoadsSlice = createSlice({
    name: 'declinedLoads',
    initialState: initialDeclinedLoadsState,
    reducers: {
        setDeclinedLoadsList: (
            state,
            action: PayloadAction<IDeclinedLoads[]>,
        ) => {
            state.declinedLoadsList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDeclinedLoadsList.pending, state => {
            state.declinedLoadsLoading = true;
            state.declinedLoadsList = [];
        });
        builder.addCase(fetchDeclinedLoadsList.fulfilled, (state, action) => {
            state.declinedLoadsLoading = false;
            state.declinedLoadsList = action.payload;
        });
        builder.addCase(fetchDeclinedLoadsList.rejected, state => {
            state.declinedLoadsLoading = false;
            state.declinedLoadsList = [SELECTED_INITIAL_STATE];
        });
    },
});

export const { setDeclinedLoadsList } = declinedLoadsSlice.actions;

const DeclinedLoadsReducer = declinedLoadsSlice.reducer;
export default DeclinedLoadsReducer;
