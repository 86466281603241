import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ILoadBoardSearchFilters,
    ILoadBoardStatusFilters,
} from '../interfaces';
import { shipmentsApi } from '../../../api/shipment/shipmentsApi';
import { LOAD_BOARD_INITIAL_STATE } from '../intialState';

import { IShipmentLoadBoardViewItem } from '../../../api/shipment/interfaces';

export const fetchDraftShipmentLoadBoardViewItems = createAsyncThunk(
    'fetchDraftShipmentLoadBoardViewItems',
    async (
        {
            loadBoardSearchFilters,
            loadBoardStatusFilters,
        }: {
            loadBoardSearchFilters: ILoadBoardSearchFilters;
            loadBoardStatusFilters: ILoadBoardStatusFilters;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await shipmentsApi.fetchShipmentDetails(
                loadBoardSearchFilters,
                loadBoardStatusFilters,
                false,
            );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const draftShipmentDetailSlice = createSlice({
    name: 'draftShipmentDetail',
    initialState: LOAD_BOARD_INITIAL_STATE,
    reducers: {
        setDraftShipmentDetails: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem[]>,
        ) => {
            state.draftShipmentDetails = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDraftShipmentLoadBoardViewItems.pending, state => {
            state.loadBoardLoading = true;
        });
        builder.addCase(
            fetchDraftShipmentLoadBoardViewItems.fulfilled,
            (state, action) => {
                state.loadBoardLoading = false;
                state.draftShipmentDetails = action.payload ?? [];
            },
        );
        builder.addCase(
            fetchDraftShipmentLoadBoardViewItems.rejected,
            state => {
                state.loadBoardLoading = false;
            },
        );
    },
});

export const { setDraftShipmentDetails } = draftShipmentDetailSlice.actions;

const DraftShipmentDetailReducer = draftShipmentDetailSlice.reducer;
export default DraftShipmentDetailReducer;
