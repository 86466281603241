import { FormFieldType } from './interfaces';

export const mobileNumberValidation = (value: string): boolean => {
    const rgx = new RegExp(/^[0-9\b]+$/);
    return rgx.test(value);
};

export const numberTypeValidation = (value: string): boolean => {
    const rgx = new RegExp('^[0-9]+$');
    return rgx.test(value);
};

export const alphanumericWithHyphen = (value: string): boolean => {
    const rgx = new RegExp('^[0-9a-zA-Z-]*$');
    return rgx.test(value);
};

export const alphanumericWithHyphenAndComma = (value: string): boolean => {
    const rgx = new RegExp('^[a-zA-Z0-9 ,-]*$');
    return rgx.test(value);
};

export const removeLeadingZeros = (value: string): string => {
    return value.replace(/^0+/, '');
};

export const floatNumberValidation = (value: string): boolean => {
    const rgx = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
    return rgx.test(value);
};

export const zipCodeValidation = (zipcode: string): boolean => {
    const rgx = new RegExp('^[a-zA-Z0-9-]*$');
    return rgx.test(zipcode);
};

export const textTypeValidation = (text: string): boolean => {
    const rgx = new RegExp('^[a-zA-Z ]*$');
    return rgx.test(text);
};

export const stringTypeValidation = (string: string): boolean => {
    const rgx = new RegExp(/^[a-zA-Z0-9 ]*$/);
    return rgx.test(string);
};

export const coordinateValidation = (coordinates: string): boolean => {
    const rgx = new RegExp(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    );
    return rgx.test(coordinates);
};

export const emailValidation = (email: string): boolean => {
    const rgx = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9-]+\.){1,2}[a-zA-Z]{2,3}$/,
    );
    const repeatedRegex = new RegExp(/\.([a-zA-Z]{2,3})\.\1$/);
    return rgx.test(email) && !repeatedRegex.test(email);
};

export const alphaNumericValidation = (aplhanumeric: string): boolean => {
    const rgx = new RegExp(/^[a-zA-Z0-9]+$/);
    return rgx.test(aplhanumeric);
};

export const mobileLengthValidation = (value: string): boolean => {
    return value.length === 10 ? true : false;
};

export const passwordValidation = (password: string): boolean => {
    const rgx = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{12,}$/,
    );
    return rgx.test(password);
};

export const specialCharacterTypeValidation = (string: string): boolean => {
    const rgx = new RegExp('^[a-zA-Z0-9!@#$&()\\-`.+,/"^%?*<>_ ]*$');
    return rgx.test(string);
};

export const destructureInput = (name: string): RegExpMatchArray | null => {
    return name.match(/^(.+?)(?:\((\d+)\))?$/);
};

export const extractNumber = (name: string): RegExpMatchArray | null => {
    return name.match(/\((\d+)\)$/);
};

export const checkPageLocation = (url: string): RegExpMatchArray | null => {
    return url.match(/loadboard\/shipmentDetails\/\d+/);
};

export const validateAccordingToType = (
    value: string | number | boolean | Date,
    type: FormFieldType,
): boolean => {
    let isValid = false;
    value = value as string;

    switch (type) {
        case 'zipcode':
            isValid = zipCodeValidation(value);
            break;
        case 'tel':
            isValid = mobileNumberValidation(value);
            break;
        case 'number':
            isValid = numberTypeValidation(value);
            break;
        case 'float':
            isValid = floatNumberValidation(value);
            break;
        case 'text':
            isValid = textTypeValidation(value);
            break;
        case 'string':
            isValid = stringTypeValidation(value);
            break;
        case 'coordinates':
            isValid = coordinateValidation(value);
            break;
        case 'email':
            isValid = emailValidation(value);
            break;
        case 'mobileLength':
            isValid = mobileLengthValidation(value);
            break;
        case 'alphanumeric':
            isValid = alphaNumericValidation(value);
            break;
        case 'passwordValidation':
            isValid = passwordValidation(value);
            break;
        case 'textarea':
            isValid = stringTypeValidation(value);
            break;
        case 'specialCharacter':
            isValid = specialCharacterTypeValidation(value);
            break;
        case 'radio':
            isValid = true;
            break;
        case 'select-one':
            isValid = true;
            break;
        case 'alphanumericWithHyphen':
            isValid = alphanumericWithHyphen(value);
            break;
        case 'password':
            isValid = passwordValidation(value);
            break;
        case 'alphanumericWithHyphenAndComma':
            isValid = alphanumericWithHyphenAndComma(value);
            break;
        default:
            isValid = false;
            break;
    }

    return isValid;
};
