export const AdditionalChargeDropdown = [
    { label: 'Select Charge', value: '0' },
    { label: 'Bedding', value: '1' },
    { label: 'Washout', value: '2' },
    { label: 'Rest', value: '3' },
    { label: 'Miscellaneous', value: '4' },
    { label: 'DOA', value: '5' },
    { label: 'Insurance', value: '6' },
];

export const YES = 'Yes';
export const NO = 'No';

export const MISCELLANEOUS = 'Miscellaneous';

export const DOA = 'DOA';
