import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { activityLogApi } from '../../../../../../../api/activityLog/activityLogApi';
import { IBreakdownActivityLogs } from '../../../../../../../api/activityLog/interfaces';

export const fetchBreakdownActivityLog = createAsyncThunk(
    'fetchBreakdownActivityLog',
    async (_, { rejectWithValue }) => {
        try {
            const response = await activityLogApi.fetchBreakdownActivityLog();
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const BREAKDOWN_ACTIVITY_LOG_INITIAL_STATE: IBreakdownActivityLogs = {
    driverName: '',
    shipmentId: 0,
    latitude: 0,
    longitude: 0,
    dateTime: '',
    orderNumber: 0,
    customerName: '',
};

export type IBreakdownActivityLogInitialState = {
    breakdownActivityLogLoading: boolean;
    breakdownActivityLogList: IBreakdownActivityLogs[];
};

const initialBreakdownActivityLogState: IBreakdownActivityLogInitialState = {
    breakdownActivityLogLoading: false,
    breakdownActivityLogList: [],
};

export const breakdownActivityLogSlice = createSlice({
    name: 'breakdownActivityLogList',
    initialState: initialBreakdownActivityLogState,
    reducers: {
        setBreakdownActivityLogList: (
            state,
            action: PayloadAction<IBreakdownActivityLogs[]>,
        ) => {
            state.breakdownActivityLogList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchBreakdownActivityLog.pending, state => {
            state.breakdownActivityLogLoading = true;
            state.breakdownActivityLogList = [];
        });
        builder.addCase(
            fetchBreakdownActivityLog.fulfilled,
            (state, action) => {
                state.breakdownActivityLogLoading = false;
                state.breakdownActivityLogList = action.payload;
            },
        );
        builder.addCase(fetchBreakdownActivityLog.rejected, state => {
            state.breakdownActivityLogLoading = false;
            state.breakdownActivityLogList = [
                BREAKDOWN_ACTIVITY_LOG_INITIAL_STATE,
            ];
        });
    },
});

export const { setBreakdownActivityLogList } =
    breakdownActivityLogSlice.actions;

const BreakdownActivityLogReducer = breakdownActivityLogSlice.reducer;
export default BreakdownActivityLogReducer;
