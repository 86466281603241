import { dispatchManagerFeatures } from '../../featureFlags/dispatchManagerFeatures';

export const PER_MILE = 'PerMile';
export const PER_CWT = 'PerCWT';
export const FLAT = 'Flat';
export const PER_HEADCOUNT = 'PerHeadCount';
export const PER_TON = 'PerTon';
export const PER_BUSHEL = 'PerBushel';
export const NONE = 'None';

const { isShowNewRateType } = dispatchManagerFeatures;

const baseRateTypes = [
    { label: 'Select Rate Type', value: NONE },
    { label: 'Per Mile', value: PER_MILE },
    { label: 'Per CWT', value: PER_CWT },
    { label: 'Flat', value: FLAT },
    { label: 'Per HeadCount', value: PER_HEADCOUNT },
];

if (isShowNewRateType) {
    baseRateTypes.push({ label: 'Per Ton', value: PER_TON });
    baseRateTypes.push({ label: 'Per Bushel', value: PER_BUSHEL });
}

export const RateTypeDropdown = baseRateTypes;
