import { getFeatureFlagValue } from './helper';
import { IDispatchManagerFeatures } from './interface';

export const dispatchManagerFeatures: IDispatchManagerFeatures = {
    isHideIntimetecMailIdUsers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_HIDE_INTIMETEC_MAIL_ID_USERS,
    ),
    isShowSettingsInvoicing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_SETTINGS_INVOICING,
    ),
    isShowInputAndDocumentPreferences: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_INPUT_AND_DOCUMENT_PREFERENCES,
    ),
    isShowSubscriptionComponent: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_SUBSCRIPTION_COMPONENT,
    ),
    isShowConnectToQuickbooks: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_CONNECT_TO_QUICKBOOKS,
    ),
    isShowPasswordResetFrequency: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_PASSWORD_RESET_FREQUENCY,
    ),
    isShowInvoiceTotalAndDriverRate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_INVOICE_TOTAL_DRIVER_RATE,
    ),
    isShowAllActivityItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_ALL_ACTIVITY_ITEMS,
    ),
    isShowDeclinedLoads: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_DECLINED_LOADS,
    ),
    isShowActivityLogBreakdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ACTIVITY_LOG_BREAKDOWN,
    ),
    isShowNewRateType: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_NEW_RATE_TYPE,
    ),
    isShowPinDropLocationBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_SHOW_PIN_DROP_LOCATION_BUG,
    ),
    isShowDifferentHomeTimeMessage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DIFFERENT_HOME_TIME_MESSAGE,
    ),
    isShowBOLRequiredInMobileApp: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_BOL_REQUIRED_IN_MOBILE_APP,
    ),
    isShowResendButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_RESEND_BUTTON,
    ),
};
