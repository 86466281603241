import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ITranslationsLanguages } from '../components/LocaleDropdown/interfaces';

interface Translations {
    [moduleName: string]: {
        [key: string]: string;
    };
}

const moduleNames = [
    'BS_AddLocationModal',
    'BS_CommodityModal',
    'BS_CommonConstants',
    'BS_CustomerDetails',
    'BS_DeleteLocationModal',
    'BS_DuplicatePindropModal',
    'BS_EstimateRatesModal',
    'BS_ShipmentDetails',
    'BS_SuccessModal',
    'BS_LocationRouteMap',
    'Cargo',
    'Common',
    'Customers',
    'Drivers',
    'LB_AttachBillOfLading',
    'LB_AssignedDrivers',
    'LB_CardModal',
    'LB_Common',
    'LB_DriverList',
    'LB_DeleteShipmentModal',
    'LB_DispatchConfirmation',
    'LB_ShipmentDetails',
    'LB_InvoiceDetails',
    'Login',
    'Navbar',
    'Settings',
    'DriverDetails',
    'CustomerDetails',
    'BrokerDetails',
    'DeactivateCustomerModal',
    'DeactivateBrokerModal',
    'Brokers',
    'PinDrop',
    'DeactivateDriverModal',
    'Associate',
    'DeactivateAssociateWarningModal',
    'Dashboard',
    'SignUp',
    'BreakdownDriverModal',
    'ResolveBreakdownModal',
    'Reports',
    'SettlementsReport',
    'DateInput',
    'BS_DeleteShipmentModal',
    'Users',
    'Support',
    'FullActivityLog',
    'DuplicateLoads',
];

async function getTranslations(
    language: ITranslationsLanguages,
): Promise<Translations> {
    const translations: Translations = {};

    await Promise.all(
        moduleNames.map(async (moduleName: string | number) => {
            const translation = await import(
                `./locales/${language}/${moduleName}.json`
            );
            translations[moduleName] = translation;
        }),
    );

    return translations;
}

(async function initI18n() {
    const enTranslations = await getTranslations('en');
    const esTranslations = await getTranslations('es');

    i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: 'en',
            fallbackLng: 'en',
            resources: {
                en: enTranslations,
                es: esTranslations,
            },
            interpolation: {
                escapeValue: false,
            },
        });

    return i18n;
})();

export default i18n;
