import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { apiCall, notifySuccess } from '../../api/axios';
import {
    IDriver,
    IDriverDetail,
    IDriverDetailPayload,
} from '../../api/driver/interfaces';
import { AssociateFormErrorState } from '../../common/associate/interfaces';
import { ITruckingCompanyModalDetails } from '../../pages/Driver/NewDriverModal/components/TruckingCompanyModal/interfaces/ITruckingCompanyModalDetails';
import { API_CONSTANTS } from '../../services/apiConstants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { IAttachmentResponse } from '../interfaces';
import { ITruckingCompany } from '../interfaces/ITruckingCompany';

const createNewDriver =
    (
        driver: IDriver,
        setFormErrorState: (
            key: keyof AssociateFormErrorState,
            value: boolean,
        ) => void,
    ) =>
    async () => {
        driver.attachments.length && (driver.attachments = []);
        const response: IGenericApiResponse<number> = await apiCall(
            'post',
            `${API_CONSTANTS.associates + API_CONSTANTS.drivers}`,
            driver,
            true,
        );

        if (
            response.status &&
            response.status >= HttpStatusCode.Ok &&
            response.status < HttpStatusCode.MultipleChoices
        ) {
            notifySuccess(t('Drivers:newDriverAddedSuccessfully'));
        } else if (
            Number(response.status) === HttpStatusCode.UnprocessableEntity
        ) {
            setFormErrorState('email', true);
        }
        return response.data;
    };

export const fetchFullDriverDetails = async (
    associateId: string | number,
): Promise<IDriverDetail> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.associates}${API_CONSTANTS.driver}/${associateId}?include=Address,TagGroups,Attachments,unavailabilities,TruckingCompany,DriverTrailers,DriverTrailers.Trailer,Contacts`,
        {},
        true,
    );
    return response.data;
};

export const updateDriverDetail = async (
    associateId: string | number,
    payload: Partial<IDriverDetailPayload>,
): Promise<number> => {
    payload.attachments?.length && (payload.attachments = []);
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.associates}${API_CONSTANTS.drivers}/${associateId}`,
        payload,
        true,
    );

    return response.status;
};

const fetchDriverProfilePicture = async (
    associateId: number,
    attachment: Partial<IAttachmentResponse> | null,
): Promise<IGenericApiResponse<Blob>> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}/${associateId}/attachments/${attachment?.attachmentId}/${attachment?.accessToken}`,
        {},
        true,
        true,
    );

    return response;
};

export const fetchTruckingCompanies = async (): Promise<ITruckingCompany[]> => {
    const response: IGenericApiResponse<ITruckingCompany[]> = await apiCall(
        'get',
        `${API_CONSTANTS.truckingcompany}?sort=Name`,
        {},
        true,
    );

    return response.data;
};

export const createTruckingCompany = async (
    payload: ITruckingCompanyModalDetails,
): Promise<number> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.truckingcompany}`,
        payload,
        true,
    );

    return response.data;
};

export const driverApi = {
    createNewDriver,
    fetchFullDriverDetails,
    updateDriverDetail,
    fetchDriverProfilePicture,
    fetchTruckingCompanies,
};
