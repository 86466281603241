export const DeclinedLoadsHeader = [
    {
        name: 'FullActivityLog:driverName',
    },
    {
        name: 'FullActivityLog:date/time',
    },
    {
        name: 'FullActivityLog:customerName',
    },
    {
        name: 'FullActivityLog:pickUpLocation',
    },
    {
        name: 'FullActivityLog:dropOffLocation',
    },
    {
        name: '',
    },
];
