import { InvoiceType, PriorityType } from '../../components/Associate/enum';
import {
    AssociateTypes,
    DriverLoadChargeTypes,
    FilterLimit,
    ShipmentStatusTypes,
} from '../../utils/constants';
import { NEW_DRIVER_INITIAL_STATE } from '../Driver/initialState';
import { FilterType } from './constants';
import { ILoadBoardInitialState } from './interfaces';

export const LOAD_BOARD_INITIAL_STATE: ILoadBoardInitialState = {
    loadBoardLoading: false,
    driverLoadInvoiceTotal: null,
    deductionChargeCommodityList: [],
    selectedDriverIdList: [],
    loadBoardSearchFilters: {
        customerName: '',
        orderDate: null,
        driverName: '',
        orderNumber: '',
        contractNumber: '',
    },
    loadBoardStatusFilters: {
        selectedBadges: [],
        limit: FilterLimit,
        offset: 0,
    },
    selectedShipmentDetail: {
        shipmentId: 0,
        customerId: 0,
        loadBoardLastUpdatedTime: '',
        customer: {
            name: '',
            associateType: AssociateTypes.customer,
            phoneNumber: '',
            faxNumber: '',
            email: null,
            notes: '',
            invoiceType: InvoiceType.brokenOut,
            autoShrinkPercentage: 0,
            priority: PriorityType.normalPriority,
            contacts: [],
            attachments: [],
            brokerFeeType: 'Percentage',
            brokerFeeAmount: '',
            address: {
                locationId: 0,
                type: 'Home',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                latitude: 0,
                longitude: 0,
                instructions: '',
            },
        },
        shipmentLocations: [],
        isCattlePORequest: false,
        driverLoads: [],
        isNew: false,
        loadNumber: 0,
        deliveryEndDate: '',
        deliveryStartDate: '',
        _49kTrucksRequired: 0,
        _52kTrucksRequired: 0,
        _55_62kTrucksRequired: 0,
        status: '',
        contractNumber: '',
        estimatedDistanceInMiles: 0,
        loadTime: '',
        timezone: '',
        weightInPounds: 0,
        unloadedWeightInPounds: 0,
        shrinkPercentage: 0,
        orderNotes: '',
        driverInstructions: '',
        shipmentCargoDetails: [],
        areAllRatesVerified: false,
        attachments: [],
        isActive: true,
        createdBy: '',
        createdDateTime: '',
    },
    shipmentDetails: [],
    draftShipmentDetails: [],
    showShipmentActionsPopover: [],
    driverLoads: [],
    showDriverLoadModal: false,
    showAttachBillOfLadingModal: false,
    isAddDriverLoad: false,
    selectedDriverLoad: null,
    selectedDriverLoadStatus: null,
    driverLoad: {
        totalDriverLoadCharges: 0,
        driverLoadId: null,
        associateId: null,
        billOfLadingNumber: '',
        unitNumber: null,
        trailerNumber: null,
        orderNumber: '',
        loadedWeightInPounds: null,
        unloadedWeightInPounds: null,
        paidWeight: null,
        headCount: null,
        deads: null,
        cripples: null,
        truckMiles: null,
        paidMiles: null,
        rateType: 'None',
        estimatedTotalRate: '',
        isRateVerified: false,
        brokerFeeType: 'Percentage',
        brokerFeeAmount: '',
        driverLoadCharges: [],
        additionalDriverLoadCharges: [],
        deductionDriverLoadCharges: [],
        shipmentId: null,
        driver: NEW_DRIVER_INITIAL_STATE.newDriver,
        attachments: [],
        driverLoadCargoDetails: [],
        isBillOfLadingReceived: false,
        status: ShipmentStatusTypes.ready,
        ownerOperatorPercent: '',
        ownerOperatorAmount: '',
        fuelSurcharge: '',
        driverRate: null,
    },
    showDriverReVerifyRateModal: false,
    reVerifyDriverCommodityTypes: [],
    updatedShipmentDetail: {
        shipmentId: 0,
        customerId: 0,
        loadBoardLastUpdatedTime: '',
        customer: {
            name: '',
            associateType: AssociateTypes.customer,
            phoneNumber: '',
            faxNumber: '',
            email: null,
            notes: '',
            invoiceType: InvoiceType.brokenOut,
            autoShrinkPercentage: 0,
            priority: PriorityType.normalPriority,
            contacts: [],
            attachments: [],
            brokerFeeType: 'Percentage',
            brokerFeeAmount: '',
            address: {
                locationId: 0,
                type: 'Home',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                latitude: 0,
                longitude: 0,
                instructions: '',
            },
        },
        shipmentLocations: [],
        isCattlePORequest: false,
        driverLoads: [],
        isNew: false,
        loadNumber: 0,
        deliveryEndDate: '',
        deliveryStartDate: '',
        _49kTrucksRequired: 0,
        _52kTrucksRequired: 0,
        _55_62kTrucksRequired: 0,
        status: '',
        contractNumber: '',
        estimatedDistanceInMiles: 0,
        loadTime: '',
        timezone: '',
        weightInPounds: 0,
        unloadedWeightInPounds: 0,
        shrinkPercentage: 0,
        orderNotes: '',
        driverInstructions: '',
        shipmentCargoDetails: [],
        areAllRatesVerified: false,
        isActive: true,
        createdBy: '',
        createdDateTime: '',
    },
    additionalDriverLoadCharges: {
        driverLoadChargeId: null,
        driverLoadChargeType: '',
        quantity: null,
        costPerItem: null,
        driverLoadId: null,
        chargeType: DriverLoadChargeTypes.addition,
        isValid: true,
    },
    deductionDriverLoadCharges: {
        driverLoadChargeId: null,
        driverLoadChargeType: '',
        quantity: null,
        costPerItem: null,
        driverLoadId: null,
        chargeType: DriverLoadChargeTypes.deduction,
        isValid: true,
    },
    selectedAdditionalCharges: [],
    selectedDeductionCharges: [],
    showDeleteShipmentModal: false,
    driverListActiveKey: null,
    canFetchDriverList: false,
    canCallShipmentListApi: false,
    showLoadBoardCanvas: {
        type: FilterType.statusSearch,
        isShow: false,
    },
    associatedDriverList: [],
    selectedCustomerName: {
        label: '',
        value: '',
    },
    selectedDriverName: {
        label: '',
        value: '',
    },
    currentShipmentDetail: {
        shipmentId: 0,
        customerId: 0,
        loadBoardLastUpdatedTime: '',
        customer: {
            name: '',
            associateType: AssociateTypes.customer,
            phoneNumber: '',
            faxNumber: '',
            email: null,
            notes: '',
            invoiceType: InvoiceType.brokenOut,
            autoShrinkPercentage: 0,
            priority: PriorityType.normalPriority,
            contacts: [],
            attachments: [],
            brokerFeeType: 'Percentage',
            brokerFeeAmount: '',
            address: {
                locationId: 0,
                type: 'Home',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                latitude: 0,
                longitude: 0,
                instructions: '',
            },
        },
        shipmentLocations: [],
        isCattlePORequest: false,
        driverLoads: [],
        isNew: false,
        loadNumber: 0,
        deliveryEndDate: '',
        deliveryStartDate: '',
        _49kTrucksRequired: 0,
        _52kTrucksRequired: 0,
        _55_62kTrucksRequired: 0,
        status: ShipmentStatusTypes.needsDrivers,
        contractNumber: '',
        estimatedDistanceInMiles: 0,
        loadTime: '',
        timezone: '',
        weightInPounds: 0,
        unloadedWeightInPounds: 0,
        shrinkPercentage: 0,
        orderNotes: '',
        driverInstructions: '',
        shipmentCargoDetails: [],
        areAllRatesVerified: false,
        attachments: [],
        isActive: true,
        createdBy: '',
        createdDateTime: '',
    },
    isShipmentStatusChanged: false,
    showOwnerOperator: false,
    selectedDriverCommodity: null,
};
